import {
    CognitoUser,
} from 'amazon-cognito-identity-js';
import userPool from '../aws-exports';

const confirmMail = ({email, code},cb) => {
    const userData = {
        Username: email,
        Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, cb);
}

export default confirmMail