import { useState } from 'react';

const TableComponent = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const [sortedData, setSortedData] = useState(data)


const handleSort = (field)=>{
    const sortdata = data?.sort((a, b) => a?.[field].localeCompare(b?.[field]))
    setSortedData(sortdata)
}

    return (
        <>
            <div className="p-2">
                <table className="min-w-full table-fixed border">
                    <thead className='bg-blue-500'>
                        <tr>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Cut_Off')}>RGI Criteria</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Best_Hit_ARO')}
                            >ARO Term</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Best_Identities')}>% Identity of Matching Region</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('AMR Gene Family')}>AMR Gene Family</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Drug Class')}>Drug Class</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Resistance Mechanism')}>Resistance Mechanism</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Antibiotic')}>Resistant Antibiotics</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Predicted_DNA')}>Predicted DNA Sequence</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Predicted_Protein')}>Predicted Protein Sequence</th>
                            <th className="py-2 px-4 text-left text-white border-2 cursor-pointer"
                            onClick={()=>handleSort('Sequence_Length')}> Sequence Length</th>
                            {/* Add more header columns as needed */}
                        </tr>
                    </thead>
                    <tbody>

                        {
                            sortedData && sortedData.map((item, index) => (
                                <tr key={index}
                                    className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                                >
                                    <td className="p-2 text-center w-10 border-2">{item?.['Cut_Off']}</td>
                                    <td className="p-2 text-center w-10 border-2">{item?.['Best_Hit_ARO']}</td>
                                    <td className="p-2 text-center w-10 border-2">{item?.['Best_Identities']}</td>
                                    <td className="p-2 text-center w-10 border-2">{item?.['AMR Gene Family']}</td>
                                    <td className="p-2 text-center w-10 border-2">{item?.['Drug Class']}</td>
                                    <td className="p-2 text-center w-10 border-2">{item?.['Resistance Mechanism']}</td>
                                    <td className="p-2 text-center w-10 border-2">{item?.['Antibiotic']}</td>
                                    <td className="p-2 text-center w-10 border-2 cursor-pointer"
                                        onClick={() => {
                                            setOpen(true);
                                            setContent({ header: "Predicted_DNA", data: item?.['Predicted_DNA'] });
                                        }}
                                    >{item?.['Predicted_DNA']?.slice(1, 15)}...</td>
                                    <td className="p-2 text-center w-10 border-2 cursor-pointer"
                                        onClick={() => {
                                            setOpen(true);
                                            setContent({ header: "Predicted_Protein", data: item?.['Predicted_Protein'] });
                                        }}
                                    >{item?.['Predicted_Protein']?.slice(0, 15)}...</td>
                                    <td className="p-2 text-center w-10 border-2 cursor-pointer">{item?.['Sequence_Length']}</td>
                                </tr>
                            ))}
                        {/* Add table rows and data here */}
                    </tbody>
                </table>
            </div>
            <Modal isOpen={open} onClose={() => setOpen(false)} content={content} />
        </>
    );
};

const Modal = ({ isOpen, onClose, content }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className={`fixed inset-0 z-20 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none w-screen h-screen overflow-hidden bg-black/40 transition-all duration-300 ease-in-out ${isOpen ? "opacity-100" : "opacity-0"}`}
            onClick={onClose}
        >
            <div className="bg-white p-4 rounded-lg w-full md:w-[350px] flex flex-col justify-center items-center z-50"
                onClick={() => null}
            >
                <h2 className="text-xl font-bold mb-2">{content.header}</h2>
                <p className="mb-4 break-all">{content.data}</p>
                <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default TableComponent;
