import {
    CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import userPool from '../aws-exports';


const registerUser = ({ name, email, password }, cb) => {


    const attributeList = [
        new CognitoUserAttribute({
            Name: 'name',
            Value: name,
        })
    ];

    userPool.signUp(
        email,
        password,
        attributeList,
        null,
        cb
    );
}

export default registerUser