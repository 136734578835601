import axios from 'axios';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { GoFileZip } from "react-icons/go";
// import userPool from "../aws-exports";

const Tools = () => {
    // const [accessToken, setAccessToken] = useState('')
    // const cognitoUser = userPool?.getCurrentUser();
    // State variables
    const [script, setScript] = useState("fastq_to_fasta"); // Current selected tool
    const [selectedFiles, setSelectedFiles] = useState([]); // Array of selected files
    const [downloadLink, setDownloadLink] = useState(null); // Download link for processed files
    const [loading, setLoading] = useState(false); // Loading state

    // Reset selected files when switching scripts
    useEffect(() => {
        setSelectedFiles([]);
    }, [script]);

    // useEffect(() => {
        // if (cognitoUser) {
            // const actkey = `CognitoIdentityServiceProvider.${cognitoUser?.pool?.clientId}.${cognitoUser.getUsername()}.accessToken`
            // setAccessToken(cognitoUser?.pool?.storage[actkey])
        // }
    // }, [cognitoUser])



    // Callback for file drop
    const onDrop = useCallback((acceptedFiles) => {
        // Concatenate new files to the existing list of selected files
        setSelectedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
        setDownloadLink(null); // Reset download link upon new file selection
    }, []);

    // Hook for handling file dropzone
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFiles) return;
        setLoading(true)

        const user = JSON.parse(localStorage.getItem('user'))

        const payload = {
            userId: user.email,
            tool: script,
            fileName: selectedFiles[0].name
        };

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/upload-tool`, payload)

            await axios.put(data.metadata.url,
                selectedFiles[0],
                {
                    headers: {
                        "Content-Type": "application/octet-stream",
                        // "Authorization": `Bearer ${accessToken}`
                    }
                }
            )
            await axios.post(`${process.env.REACT_APP_API_URL}/tool-task`, { key: data?.metadata?.key, tool: script })
            setDownloadLink(`https://kg-dev01.s3.us-west-1.amazonaws.com/${data.metadata.key}`);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }

    };

    // Remove selected file
    const handleFileRemove = (indexToRemove) => {
        setSelectedFiles(prevFiles => prevFiles.filter((file, index) => index !== indexToRemove)); // Remove file from selected files
    };

    // Handle file change for the hidden file input
    const handleFileChange = (e) => {
        const files = e.target.files;
        setSelectedFiles(Array.from(files));
    };

    // JSX
    return (
        <div className="lg:col-span-5 min-h-screen p-[25px]">
            <h1 className="text-[20px] font-[700]">Tools</h1>
            <div className="h-full w-full flex flex-col gap-3 items-center justify-center">
                <form onSubmit={handleSubmit} className="lg:w-[800px] rounded-lg flex flex-col items-center gap-[50px] justify-center lg:min-h-[600px] bg-[#fff] py-5" style={{
                    boxShadow: "0px -0.743px 0.743px 0px rgba(13, 34, 71, 0.12) inset",
                    filter: "drop-shadow(0px 20.799px 80.224px rgba(13, 34, 71, 0.10)) drop-shadow(0px 14.856px 29.713px rgba(13, 34, 71, 0.10)) drop-shadow(0px 0.743px 5.943px rgba(13, 34, 71, 0.12))"
                }}>

                    {/* Select Tool */}
                    <div className="flex flex-col gap-3 justify-center items-center">
                        <label htmlFor="script" className="text-2xl font-bold">Select Tool</label>
                        <select
                            name="script"
                            id="script"
                            value={script}
                            onChange={(e) => setScript(e.target.value)}
                            className="p-2 rounded-sm ring-2 ring-black"
                        >
                            <option value="concatenate_files" disabled>Concatenate Files</option>
                            {/* <option value="FASTA to FASTQ">FASTA to FASTQ</option> */}
                            <option value="fastq_to_fasta">FASTQ to FASTA</option>
                        </select>
                    </div>

                    {/* Conditional rendering of Dropzone and selected files for 'concatenate_files' */}
                    {script === "concatenate_files" && (
                        <>
                            <div {...getRootProps()} className="dropzone border-dashed border-2 border-gray-300 p-10 rounded-md">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <p>Drag 'n' drop files here, or click to select files</p>
                                )}
                            </div>
                            {selectedFiles.map((file, index) => (
                                <div key={index} className="flex items-center justify-between w-full py-3 px-4 bg-gray-100 rounded-md">
                                    <div className="flex items-center">
                                        <GoFileZip className="text-gray-500 mr-2" />
                                        <p className="text-gray-800">{file.name}</p>
                                    </div>
                                    <button
                                        onClick={() => handleFileRemove(index)}
                                        className="text-red-600 hover:text-red-800 focus:outline-none"
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                            <div className="flex justify-center items-center gap-4">
                                <div className="flex flex-col items-center">
                                    <img src="/page-multiple-svgrepo-com.svg" alt="Many FASTQ" className="w-24 h-24" />
                                    <p className="text-lg text-center font-medium mt-2">Many FASTQ</p>
                                </div>
                                <img src="/arrow-pointing-to-right-svgrepo-com.svg" alt="Arrow Right" className="w-12 h-12" />
                                <div className="flex flex-col items-center">
                                    <img src="/page-svgrepo-com.svg" alt="One FASTQ" className="w-24 h-24" />
                                    <p className="text-lg text-center font-medium mt-2">One FASTQ</p>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Conditional rendering of file input for 'FASTA to FASTQ' and 'FASTQ to FASTA' scripts */}
                    {script === 'fastq_to_fasta' && (
                        <>
                            <input
                                type="file"
                                name="upload"
                                id="upload"
                                disabled={loading}
                                required
                                className="hidden"
                                onChange={handleFileChange}
                            />
                            <label
                                htmlFor="upload"
                                style={loading ? { cursor: 'default' } : { cursor: "pointer" }}
                                className="flex cursor-pointer items-center justify-center px-[20px] gap-[8px] py-[8px] rounded-[8px] font-bold text-black bg-blue-400"
                            >
                                Upload{" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                >
                                    <path
                                        d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12.75 6L9 2.25L5.25 6"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M9 2.25V11.25"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </label>
                            {/* Files list */}
                            {selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                                <div key={index} className="py-3 px-[80px] rounded-md border-2 border-dashed flex items-center gap-2">
                                    <GoFileZip /> {file.name}
                                    <button onClick={() => handleFileRemove(index)} className="text-red-600 hover:text-red-800 focus:outline-none">
                                        Remove
                                    </button>
                                </div>
                            ))}
                            {/* <div className="flex justify-center items-center gap-4">
                                <div className="flex flex-col items-center">
                                    <img src="/page-svgrepo-com.svg" alt="FASTA" className="w-24 h-24" />
                                    <p className="text-lg text-center font-medium mt-2"> FASTA</p>
                                </div>
                                <img src="/arrow-pointing-to-right-svgrepo-com.svg" alt="Arrow Right" className="w-12 h-12" />
                                <div className="flex flex-col items-center">
                                    <img src="/page-svgrepo-com.svg" alt="FASTA" className="w-24 h-24" />
                                    <p className="text-lg text-center font-medium mt-2">FASTQ</p>
                                </div>
                            </div> */}
                            <div className="flex justify-center items-center gap-4">
                                <div className="flex flex-col items-center">
                                    <img src="/page-svgrepo-com.svg" alt="FASTA" className="w-24 h-24" />
                                    <p className="text-lg text-center font-medium mt-2"> FASTQ</p>
                                </div>
                                <img src="/arrow-pointing-to-right-svgrepo-com.svg" alt="Arrow Right" className="w-12 h-12" />
                                <div className="flex flex-col items-center">
                                    <img src="/page-svgrepo-com.svg" alt="FASTA" className="w-24 h-24" />
                                    <p className="text-lg text-center font-medium mt-2">FASTA</p>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Upload and Download Buttons */}
                    <div className="flex items-center gap-[100px]">
                        <button
                            type="submit"
                            disabled={selectedFiles.length === 0}
                            className={`flex items-center justify-center px-5 py-2 rounded text-lg ${selectedFiles.length === 0 ? 'bg-gray-400 text-white cursor-not-allowed' : 'text-black font-bold bg-blue-500 hover:bg-blue-600'
                                }`}
                        >
                            Upload Now
                        </button>
                        {downloadLink && (
                            <a href={downloadLink} className="flex cursor-pointer items-center justify-center px-[20px] gap-[8px] py-[8px] rounded-[8px] font-bold text-black bg-blue-400">
                                Download File
                            </a>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Tools;
