import {
    createBrowserRouter,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import Workflow from "./pages/Workflow";
import BaseLayout from "./layout/BaseLayout";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import Private from "./layout/Private";
import DetailsPage from "./pages/DetailsPage";
import Tools from "./pages/Tools";
import Contact from "./pages/Contact";
const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <BaseLayout>
                <HomePage />
            </BaseLayout>
        )
    },
    {
        path: "workflow",
        element: (
            <BaseLayout>
                <Private>
                    <Workflow />
                </Private>
            </BaseLayout>
        )
    },
    {
        path: "workflow/:processId",
        element: (
            <BaseLayout>
                <Private>
                    <DetailsPage />
                </Private>
            </BaseLayout>
        )
    },
    {
        path: "contact",
        element: (
            <BaseLayout>
                    <Contact />,
            </BaseLayout>
        )
    },
    {
        path: "tools",
        element: (
            <BaseLayout>
                <Private>
                    <Tools />,
                </Private>
            </BaseLayout>
        )
    },
    {
        path: "sign_in",
        element: <SignInPage />,
    },
    {
        path: "sign_up",
        element: <SignUpPage />,
    },
    {
        path: "auth",
        element: <Private />,
    },
]);

export default router;