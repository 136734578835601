import React from 'react'

export default function Contact() {
  const url = 'https://calendly.com/matteomiralaie-kinggenomics/30min'
  return (
    <div className="lg:col-span-5 min-h-screen p-[25px] overflow-y-auto" >
      <div className="h-full w-full flex flex-col gap-3 items-center justify-center">
        <iframe src={url} title='calendly' frameborder="0" className='w-full min-h-full' />

      </div>

    </div>
  )
}
