import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { FaChevronLeft } from "react-icons/fa";
import TableComponent from "../components/Table";
import userPool from "../aws-exports";

const DetailsPage = () => {
  const { processId } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [message, setMessage] = useState(null)
  const [accessToken, setAccessToken] = useState('')
  const [refetch, setRefetch] = useState(true)

  const navigate = useNavigate()
  const cognitoUser = userPool.getCurrentUser();


  const getData = async () => {
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${accessToken}`)
    headers.append('Content-Type', 'application/json')
    const res = await fetch(`${process.env.REACT_APP_API_URL}/process/${processId}`, {
      method: 'GET',
      headers,
    })
    const result = await res.json()
    // console.log(result)
    if (res.ok) {
      if (result) {
        setData(result)
      }
      setMessage(result.message)
      return result
    } else {
      setMessage(result.message)
    }

  }

  useEffect(() => {
    if (cognitoUser) {
      const actkey = `CognitoIdentityServiceProvider.${cognitoUser?.pool?.clientId}.${cognitoUser.getUsername()}.accessToken`
      setAccessToken(cognitoUser?.pool?.storage[actkey])
    }
    if (refetch) {
      if (!(data?.file_processed || data?.result_key)) {

        setLoading(true)
        getData().then((res) => {
          setLoading(false)
          if (res?.table) {
            setTableData(res.table)
          }
        })

      }
    }

  }, [refetch])


  useEffect(() => {

    const reload = setInterval(function () {
      // navigate(0)
      setRefetch((prev) => !prev)
    }, 4000);


    return () => {
      setData(null)
      setTableData(null)
      clearInterval(reload)
    }

    // eslint-disable-next-line
  }, [])


  return (
    <div className="lg:col-span-5 min-h-screen p-[25px] relative"
      // style={{ backgroundImage: "url('/images/gradient.png')", backgroundSize: "cover" }}
      style={{
        backgroundImage: "url('/images/bg-gradient.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundSize: "900px 900px"
      }}
    >
      <div
        className="absolute top-0 left-0 p-16"
      >
        <div className="absolute bg-black rounded-md text-white flex gap-2 px-3 py-2 justify-center items-center cursor-pointer z-50"
          onClick={() => navigate(-1)}
        >

          <FaChevronLeft />
          <button
            className="cursor-pointer"
          >Back</button>
        </div>
      </div>
      <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center">
        {loading ?
          <div className="flex flex-col justify-center items-center space-y-4">
            <img className="w-16" src="/spin.svg" alt="" />
            <p className="text-lg font-bold">Loading workflow data</p>
          </div>
          :
          data ?
            !(data.file_processed || data.result_key) ?
              <div className="flex flex-col justify-center items-center space-y-4">
                <img className="w-16" src="/spin.svg" alt="" />
                <div className="w-full">

                  <ProgressBar completed={data.progress || 0} />
                  {
                    data?.step &&
                    <h1 className="mt-5 text-center">Current step: <span className="font-bold capitalize text-lg">{data.step}</span></h1>
                  }
                </div>
                <p className="text-lg font-bold">Still processing. Please check back later</p>
                <p className="text-lg font-bold">Running workflow.</p>
                <div className="flex flex-col gap-1 text-center">
                  <h1 className="mt-4 text-lg font-semibold"> Estimate Time to Completion:</h1>
                  <p>1 MB - 10 MB: &lt; 5 minutes</p>
                  <p>11 MB -100 MB: &lt; 30 Minutes</p>
                  <p>101 MB - 1 GB:  &lt; 1 Hour</p>
                  <p>2+ GB: &gt; 2 Hours</p>
                </div>
              </div>
              :
              <div className="flex flex-col gap-7 min-w-full">
                <div className="flex flex-col gap-3 justify-center items-center">
                  <h1 className="text-2xl font-bold">Workflow details</h1>

                  {/* <a href={`https://${data.bucket}.s3.${data.region}.amazonaws.com/results/${data.process_id}/result.zip`} */}
                  <a href={`https://${data.bucket}.s3.${data.region}.amazonaws.com/${data.result_key}`}
                    className="bg-black px-4 py-2 rounded-md text-white"
                  >Download file</a>
                </div>

                <div className="w-full max-h-[700px] overflow-scroll">


                  {
                    tableData &&
                    <TableComponent data={tableData} />
                  }
                </div>
              </div>
            :
            <div className="flex flex-col justify-center items-center space-y-4">
              {message &&
                <div>
                  <p className="text-lg font-bold">{message}</p>
                </div>
              }

            </div>
        }
      </div>
    </div>
  );
};

export default DetailsPage;
