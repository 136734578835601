import React from 'react'

export default function WorkflowIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
        >
            <path
                d="M15.5582 1.92627H13.9748C12.1582 1.92627 11.1998 2.8846 11.1998 4.70127V6.2846C11.1998 8.10127 12.1582 9.0596 13.9748 9.0596H15.5582C17.3748 9.0596 18.3332 8.10127 18.3332 6.2846V4.70127C18.3332 2.8846 17.3748 1.92627 15.5582 1.92627ZM6.03317 11.4513H4.44984C2.62484 11.4513 1.6665 12.4096 1.6665 14.2263V15.8096C1.6665 17.6346 2.62484 18.5929 4.4415 18.5929H6.02484C7.8415 18.5929 8.79984 17.6346 8.79984 15.8179V14.2346C8.80817 12.4096 7.84984 11.4513 6.03317 11.4513ZM5.2415 9.07627C5.71098 9.07627 6.17586 8.9838 6.6096 8.80414C7.04334 8.62448 7.43744 8.36115 7.76941 8.02918C8.10138 7.69721 8.36471 7.3031 8.54437 6.86936C8.72403 6.43562 8.8165 5.97075 8.8165 5.50127C8.8165 5.03179 8.72403 4.56692 8.54437 4.13318C8.36471 3.69944 8.10138 3.30533 7.76941 2.97336C7.43744 2.64139 7.04334 2.37806 6.6096 2.1984C6.17586 2.01874 5.71098 1.92627 5.2415 1.92627C4.29335 1.92627 3.38404 2.30292 2.7136 2.97336C2.04315 3.64381 1.6665 4.55312 1.6665 5.50127C1.6665 6.44942 2.04315 7.35873 2.7136 8.02918C3.38404 8.69962 4.29335 9.07627 5.2415 9.07627ZM14.7582 18.5946C15.7063 18.5946 16.6156 18.218 17.2861 17.5475C17.9565 16.8771 18.3332 15.9678 18.3332 15.0196C18.3332 14.0715 17.9565 13.1621 17.2861 12.4917C16.6156 11.8213 15.7063 11.4446 14.7582 11.4446C13.81 11.4446 12.9007 11.8213 12.2303 12.4917C11.5598 13.1621 11.1832 14.0715 11.1832 15.0196C11.1832 15.9678 11.5598 16.8771 12.2303 17.5475C12.9007 18.218 13.81 18.5946 14.7582 18.5946Z"
                fill="black"
            />
        </svg>
    )
}
