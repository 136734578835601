import React, { useEffect, useState } from 'react'
import { Navigate } from "react-router-dom";
import userPool from '../aws-exports';


export default function Private({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    useEffect(() => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }

    }, [])

    if (isAuthenticated === false) return <Navigate to='/sign_in' />

    return (

        <>
            {children}
        </>
    )
}
