import React from 'react'

export default function SettingIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7833 4.51662L11.475 2.02495C10.65 1.54995 9.35834 1.54995 8.53334 2.02495L4.18334 4.53328C2.45834 5.69995 2.35834 5.87495 2.35834 7.73328V12.2583C2.35834 14.1166 2.45834 14.3 4.21667 15.4833L8.525 17.975C8.94167 18.2166 9.475 18.3333 10 18.3333C10.525 18.3333 11.0583 18.2166 11.4667 17.975L15.8167 15.4666C17.5417 14.3 17.6417 14.125 17.6417 12.2666V7.73328C17.6417 5.87495 17.5417 5.69995 15.7833 4.51662ZM10 12.7083C8.50834 12.7083 7.29167 11.4916 7.29167 9.99995C7.29167 8.50828 8.50834 7.29162 10 7.29162C11.4917 7.29162 12.7083 8.50828 12.7083 9.99995C12.7083 11.4916 11.4917 12.7083 10 12.7083Z" fill="black" />
        </svg>

    )
}
