import React, { useState } from "react";
import { GoFileZip } from "react-icons/go";
import { useNavigate } from "react-router-dom";
// import userPool from "../aws-exports";
import axios from "axios";

const Workflow = () => {
  const navigate = useNavigate()
  const [script, setScript] = useState("porechop_filtlong_flye");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false)
  // const [accessToken, setAccessToken] = useState('')
  // const cognitoUser = userPool?.getCurrentUser();

  // useEffect(() => {
  //   if (cognitoUser) {
  //     const actkey = `CognitoIdentityServiceProvider.${cognitoUser?.pool?.clientId}.${cognitoUser.getUsername()}.accessToken`
  //     setAccessToken(cognitoUser?.pool?.storage[actkey])
  //   }
  // }, [cognitoUser])


  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;
    setLoading(true)

    const user = JSON.parse(localStorage.getItem('user'))

    const payload = {
      userId: user.email,
      script,
      fileName: selectedFile.name
    };

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, payload)

      await axios.put(data.metadata.url,
        selectedFile,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            // "Authorization": `Bearer ${accessToken}`
          }
        }
      )
      await axios.post(`${process.env.REACT_APP_API_URL}/process`, {
        processId: data.metadata.processId,
      })
      navigate(`/workflow/${data.metadata.processId}`)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  };
  return (
    <div className="lg:col-span-5 min-h-screen p-[25px]">
      <h1 className="text-[20px] font-[700]">Workflow Analysis</h1>
      <div className="h-full w-full flex flex-col gap-3 items-center justify-center">
        <form
          onSubmit={handleSubmit}
          style={{
            boxShadow: "0px -0.743px 0.743px 0px rgba(13, 34, 71, 0.12) inset",
            filter:
              "drop-shadow(0px 20.799px 80.224px rgba(13, 34, 71, 0.10)) drop-shadow(0px 14.856px 29.713px rgba(13, 34, 71, 0.10)) drop-shadow(0px 0.743px 5.943px rgba(13, 34, 71, 0.12))",
          }}
          className="lg:w-[800px] rounded-lg flex flex-col items-center gap-[50px] justify-center  lg:min-h-[600px] bg-[#fff] py-5"
        >
          <div className="flex flex-col gap-3 justify-center items-center">
            <label htmlFor="script" className="text-2xl font-bold text-[#000]">Select Workflow</label>
            <select name="script" id="script" value={script}
              className="p-2 rounded-sm ring-2 ring-black"
              onChange={(e) => setScript(e.target.value)}>
              <option className="text-center" value="porechop_filtlong_flye">Genome Assembly</option>
              <option className="text-center" value="porechop_filtlong_flye_rgi">Combating Antibiotic-Resistant Bacteria</option>
            </select>

          </div>
          <input
            type="file"
            name="upload"
            id="upload"
            disabled={loading}
            required
            className="hidden"
            onChange={handleFileChange}
          />
          <label
            style={loading ? { cursor: 'not-allowed' } : { cursor: "pointer" }}
            htmlFor="upload"
            className={
              "flex cursor-pointer items-center justify-center px-[20px] gap-[8px] py-[8px] rounded-[8px] text-white bg-black"
            }
          >
            Upload{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.75 6L9 2.25L5.25 6"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 2.25V11.25"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </label>
          {selectedFile && <p className="py-3  px-[80px] rounded-md border-2 border-dashed flex items-center gap-2"><GoFileZip /> {selectedFile?.name}</p>}
          <div className="flex  justify-center w-full">
            <div className="flex flex-col items-center">
              <div className="rounded-[12px] box_custom">P</div>
              <p className="text-[14px] w-[54px] text-center font-[500] mt-2">
                Porechop
              </p>
            </div>
            <div className="w-[106px] h-[2px] mt-[26px] opacity-10 bg-black"></div>
            <div className="flex flex-col items-center">
              <div className="rounded-[12px] box_custom">F</div>
              <p className="text-[14px] text-center font-[500] mt-2">
                Filtlong
              </p>
            </div>
            <div className="w-[106px] h-[2px] mt-[26px] opacity-10 bg-black"></div>
            <div className="flex flex-col items-center">
              <div className="rounded-[12px] box_custom">S</div>
              <p className="text-[14px] text-center font-[500] mt-2">Seqtk</p>
            </div>
            <div className="w-[106px] h-[2px] mt-[26px] opacity-10 bg-black"></div>
            <div className="flex flex-col items-center">
              <div className="rounded-[12px] box_custom">F</div>
              <p className="text-[14px] text-center font-[500] mt-2">Flye</p>
            </div>
            {script === "porechop_filtlong_flye_rgi" &&
              <>
                <div className="w-[106px] h-[2px] mt-[26px] opacity-10 bg-black"></div>
                <div className="flex flex-col items-center">
                  <div className="rounded-[12px] box_custom">R</div>
                  <p className="text-[14px] text-center font-[500] mt-2">RGI</p>
                </div>
              </>
            }
          </div>

          {loading && <div className="flex items-center flex-col">
            <img className="w-[60px]" src="/spin.svg" alt="" />
            <p>Sending file for processing... please wait</p>
          </div>}

          <div className="flex items-center gap-[100px]">
            <button
              disabled={selectedFile ? false : true}
              className={
                `flex items-center justify-center px-[20px] gap-[8px] py-[8px] rounded-[8px] text-white ${loading ? "bg-gray-500" : "bg-black"}`
              }
            >
              Upload Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Workflow;
