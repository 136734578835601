import React from 'react'

export default function SignInIcon() {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 18.9583C5.55829 18.9583 1.54163 14.9416 1.54163 9.99996C1.54163 5.05829 5.55829 1.04163 10.5 1.04163C10.8416 1.04163 11.125 1.32496 11.125 1.66663C11.125 2.00829 10.8416 2.29163 10.5 2.29163C6.24996 2.29163 2.79163 5.74996 2.79163 9.99996C2.79163 14.25 6.24996 17.7083 10.5 17.7083C14.75 17.7083 18.2083 14.25 18.2083 9.99996C18.2083 9.65829 18.4916 9.37496 18.8333 9.37496C19.175 9.37496 19.4583 9.65829 19.4583 9.99996C19.4583 14.9416 15.4416 18.9583 10.5 18.9583Z" fill="white" />
            <path d="M11.3332 9.7917C11.1749 9.7917 11.0166 9.73337 10.8916 9.60837C10.6499 9.3667 10.6499 8.9667 10.8916 8.72503L17.7249 1.8917C17.9666 1.65003 18.3666 1.65003 18.6082 1.8917C18.8499 2.13337 18.8499 2.53337 18.6082 2.77503L11.7749 9.60837C11.6499 9.73337 11.4916 9.7917 11.3332 9.7917Z" fill="white" />
            <path d="M18.8333 6.31663C18.4917 6.31663 18.2083 6.03329 18.2083 5.69163V2.29163H14.8083C14.4667 2.29163 14.1833 2.00829 14.1833 1.66663C14.1833 1.32496 14.4667 1.04163 14.8083 1.04163H18.8333C19.175 1.04163 19.4583 1.32496 19.4583 1.66663V5.69163C19.4583 6.03329 19.175 6.31663 18.8333 6.31663Z" fill="white" />
        </svg>

    )
}
