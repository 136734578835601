export const partnarLogos = [
    {name:'Claremont Bio', image:'/logos/kg-logo-1.png'},
    {name:'Nasa', image:'/logos/kg-logo-2.png'},
    {name:'CH', image:'/logos/kg-logo-3.jpg'},
]

export const testimonials = [
    {
        name: 'Mark Brown',
        title: 'Vice President of Research, Claremont BioSolutions LLC',
        image: '/images/profile.png',
        content: 'We approached King Genomics to develop a bioinformatics pipeline to improve our pathogen ID, metagenomics, and WGS workflow. The KG team developed a cloud-based pipeline that included fast microbial analysis and genome assembly for Oxford Nanopore sequencing datasets. Their custom cloud-based bioinformatics software is easy to use and has minimized our processing time and analysis, allowing us to rapidly turn around sample results.'
    },
    {
        name: 'Robert Doebler',
        title: ' President, Claremont BioSolutions LLC',
        image: '/images/profile.png',
        content: 'The team at King Genomics is knowledgeable, responsive, and dedicated to supporting our needs. We would recommend King Genomics for groups needing custom cloud-based bioinformatics pipelines.'
    },
]