import React, { useState } from 'react'
import { LuLoader2 } from 'react-icons/lu'
import confirmMail from '../../helpers/confirmMail'
import { useNavigate } from 'react-router-dom'

export default function ConfirmEmail({ email }) {
    const [data, setData] = useState({ code: '', email })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')

    const navigate = useNavigate()

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(false)
        setMessage('')
        setLoading(true)
        try {
            confirmMail({
                email: data.email,
                code: data.code
            }, function (err, result) {
                if (err) {
                    setMessage(err.message || JSON.stringify(err))
                    setError(true)
                    return
                }
                navigate('/sign_in')
                setMessage(JSON.stringify(result))
            })
        } catch (err) {
            console.error(err)
            setMessage(err.message)
            setError(true)
        } finally {
            setLoading(false)
        }
    }
    return (
        <form onSubmit={handleSubmit} className='w-3/6 flex flex-col gap-5' autoComplete='off'>
            <div className='flex gap-3'>
                <p className='font-semibold'>Email:</p>
                <p>{data.email}</p>
                </div>
            <div className='flex flex-col gap-3'>
                <label className='font-semibold' htmlFor="code">Code</label>
                <input onChange={handleChange}
                    value={data.code}
                    name='code'
                    type="code" id='code' className='px-2 border border-[#EDEDED] bg-transparent rounded-lg h-[46px]' />
            </div>
            {/* Error message */}
            <p className='my-2'>{error && message}</p>
            {message}

            <div className='col-span-2'>
                <button type='submit' className='w-full p-2 bg-[#85C9EA] text-black font-semibold rounded-lg'>
                    <LuLoader2 className={`${loading ? 'animate-spin block' : 'hidden'}`} />
                    {loading ? 'Loading...' : ''}  {/* loading icon */}
                    Confirm
                </button>
            </div>
        </form>
    )
}
