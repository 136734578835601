import {
	AuthenticationDetails,
	CognitoUser
} from 'amazon-cognito-identity-js';
import * as AWS from "@aws-sdk/client-cognito-identity-provider";
import userPool from '../aws-exports';


const client = new AWS.CognitoIdentityProvider({ region: "us-west-2" });

const loginUser = ({ email, password }, cb) => {

	const authenticationData = {
		Username: email,
		Password: password,
	};
	// const authenticationData = {
	// 	Username: 'msvirous@gmail.com',
	// 	Password: 'testpass123',
	// };
	const authenticationDetails = new AuthenticationDetails(
		authenticationData
	);
	const userData = {
		Username: email,
		Pool: userPool,
	};
	const cognitoUser = new CognitoUser(userData);
	cognitoUser.authenticateUser(authenticationDetails, {
		onSuccess: async function (result) {
			cb()
			const accessToken = result.getAccessToken().getJwtToken();
			const input = {
				AccessToken: accessToken
			};
			const command = new AWS.GetUserCommand(input);
			const response = await client.send(command);
			const user = {}
			response.UserAttributes.map(attr => {
				user[attr.Name] = attr.Value
			})

			localStorage.setItem('user', JSON.stringify(user))

		},

		onFailure: function (err) {
			alert(err.message || JSON.stringify(err));
		},
	});
}

export default loginUser