import React from 'react'

export default function ContactIcon() {
    return (
        <svg version="1.1" viewBox="0 0 2048 2048" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
<path transform="translate(414,139)" d="m0 0h13l19 3 18 6 15 8 14 11 7 7 10 13 15 26 14 24 13 23 10 17 12 21 14 24 16 28 10 17 16 28 14 24 16 28 8 15 5 14 3 13 1 9v13l-2 16-5 17-8 16-7 11-11 12-14 11-24 15-17 10-21 13-28 17-15 9-28 17-26 16-9 5-1 3 12 48 10 33 14 40 15 39 13 31 24 54 33 66 12 22 13 24 9 16 11 19 21 35 12 19 16 25 14 21 13 19 11 16 14 19 13 18 14 18 20 25 9 11 13 15 12 14 14 15 1 3h2l7 8 25 25 10 8v2l6 1 22-12 26-14 23-13 28-15 29-16 46-25 13-5 12-3 8-1h24l16 3 16 6 16 9 10 8 7 6 9 11 9 14 16 28 15 26 11 19 90 156 11 19 10 19 5 14 3 15v23l-3 17-6 17-8 15-8 11h-2l-2 4-10 9-14 9-19 10-28 13-35 17-28 13-33 16-28 13-33 16-20 9-16 8-20 9-16 8-29 14-9-1-20-6-25-9-26-11-29-14-24-13-20-12-25-16-20-14-15-11-20-15-16-13-13-11-11-9-15-13-12-11-8-7-9-9-8-7-56-56-7-8-7-7-7-8-13-15-13-14-9-11-13-15-9-11-11-13-11-14-12-15-10-13-14-18-14-19-10-14-26-36-14-21-13-19-15-23-16-25-12-19-36-60-28-50-13-24-14-27-10-19-16-32-11-23-17-37-16-36-16-39-15-38-11-29-10-29-14-42-12-40-12-44-9-38-7-33-8-46-6-46-3-35-1-16-1-43 1-30 3-35 6-38 6-27 4-11 12-8 17-12 25-17 23-16 41-28 23-16 32-22 19-13 23-16 19-13 23-16 21-14 16-8 16-5z"/>
<path transform="translate(1974,488)" d="m0 0h4l1 10v903l-3 15-6 14-6 9-9 10-8 7-14 8-16 5-18 2h-538l-6-9-14-24-15-26-16-28-14-24-30-52-12-18-9-11-9-10-8-8-14-11-13-9-16-9-17-8-21-7-23-5-20-2h-25l-24 3-24 6-20 8-17 8-23 13-30 16-18 10-24 13-8 5-5-1-7-8-9-10-12-14-9-11-10-12-13-17-12-15-12-17-14-19-13-19-26-39-12-19-15-24-17-29-14-24-14-26-12-22-10-19-8-16-15-31-16-36-11-25-14-35-15-42-5-15-1-6 28-17 21-13 26-16 22-13 21-14 13-10 4-3 5 2 11 8 22 15 16 11 22 15 73 50 32 22 13 9 24 16 11 8 25 17 16 11 22 15 51 35 35 24 32 22 22 15 23 16 24 16 10 7 12 5 5 1h16l12-4 15-9 10-7 16-11 19-13 13-9 54-37 19-13 23-16 15-10 16-11 20-14 12-8 19-13 23-16 12-8 23-16 15-10 29-20 54-37 19-13 22-15 13-9 19-13 13-9 41-28 22-15 20-14 12-8 48-33 22-15 25-17 16-11 19-13z"/>
<path transform="translate(1812,427)" d="m0 0h77l5 1v2l-18 11-15 11-22 15-16 11-22 15-32 22-22 15-23 16-24 16-11 8-25 17-32 22-25 17-23 16-12 8-23 16-50 34-13 9-83 57-12 8-23 16-24 16-11 8-19 13-22 15-23 16-15 10-32 22-3 2-15-10-19-13-16-11-22-15-23-16-25-17-22-15-48-33-22-15-23-16-12-8-19-13-16-11-23-16-24-16-11-8-12-8-35-24-35-24-23-16-12-8-3-3 3-11 5-21 3-19v-39l-4-25-5-19-4-10v-4z"/>
</svg>

    )
}
