import React, { useEffect, useState } from "react";
import SignInIcon from "../components/icons/sign-in";
import { Link, Navigate } from "react-router-dom";

import { partnarLogos, testimonials } from '../data'
import userPool from "../aws-exports";

const HomePage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }

  }, [])

  if (isAuthenticated === true) return <Navigate to='/workflow' />

  return (
    <div className="lg:col-span-5 min-h-screen p-[25px] relative overflow-y-scroll"
      // style={{ backgroundImage: "url('/images/gradient.png')", backgroundSize: "cover" }}
      style={{
        backgroundImage: "url('/images/bg-gradient.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundSize: "900px 900px"
      }}
    >
      <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center mt-48" >
        <div className="w-2/3 flex flex-col gap-20 justify-center items-center">

          {/* header text */}
          <div className=" text-center flex flex-col gap-10">
            <h1 className="text-[32px] font-bold">Simplify Your Science: Streamline Bioinformatics with Ease and Speed</h1>
            <p className="text-[18px]">Experience breakthrough efficiency with our cloud-powered platform—delivering customizable workflows, time-saving automation, and the capability to handle massive datasets effortlessly.</p>
          </div>
          {/* action button */}

          <div>
            <Link to={'/sign_in'} className="bg-black text-white flex gap-3 py-3 px-5 rounded-lg items-center font-semibold">
              Sign In <SignInIcon />
            </Link>
          </div>
          {/* logo row */}

          <div className='w-full flex flex-col items-center justify-center gap-10'>
            <h1 className="text-[24px] font-bold text-center">Partnered with:</h1>
            <div className="flex overflow-hidden w-full justify-center gap-16">

              {
                partnarLogos.map((item, index) => (
                  <img src={item.image} alt={item.name} className="w-auto object-contain rounded h-32" />
                ))
              }
            </div>
          </div>

          {/* Client Testimonials */}
          <div className="flex flex-col w-full items-center justify-center">
            <h1 className="text-[24px] font-bold text-center">Client Testimonials</h1>
            <div className="flex justify-around gap-2 w-full my-10">
              {
                testimonials.map((item, index) => (
                  <div className="w-[300px] h-auto"
                    key={index}>
                    <div className="bg-white p-5 rounded-lg">
                    <div className="flex gap-7 items-center mb-3">
                      {/* <img src={item.image} alt="" className="w-[45px] h-[45px] rounded-full ring-2 p-1" /> */}
                      <h1 className="text-normal font-semibold">{item.name}</h1>
                    </div>
                    <p className="text-xs text-gray-500/70 mb-3">{item.title}</p>
                    <p className="text-sm">{item.content}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default HomePage;
