import React from "react";
import WorkflowIcon from "../components/icons/workflow";
import { Link, useNavigate } from "react-router-dom";
import SettingIcon from "../components/icons/setting";
import ContactIcon from "../components/icons/contact";
import userPool from "../aws-exports";

function BaseLayout({ children }) {
    const cognitoUser = userPool.getCurrentUser()
    const navigate = useNavigate()


    return (
        <div className="grid grid-cols-1 overflow-hidden min-h-screen bg-[#EDEFF4] lg:grid-cols-6">
            <div className="min-h-screen flex items-center flex-col jb  bg-[#000000]">
                <Link to={'/'} className="flex items-center w-full justify-center border-b-[#FFFFFF1F] border-b p-[25px]">
                    <img src="/logo.png" alt="" />
                </Link>
                <div className="flex flex-col gap-3 items-center w-full justify-center border-b-[#FFFFFF1F] border-b p-[25px]">
                    <Link to={'/workflow'} className="w-full cursor-pointer bg-[#85C9EA] flex items-center gap-[10px] rounded-[5px] p-[10px]">
                        <WorkflowIcon />
                        <p to='/workflow' className="text-[14px] font-[600]">Workflow Analysis</p>
                    </Link>
                    <Link to={'/tools'} className="w-full cursor-pointer bg-[#85C9EA] flex items-center gap-[10px] rounded-[5px] p-[10px]">
                        <SettingIcon />
                        <p className="text-[14px] font-[600]">All Tools</p>
                    </Link>
                    <Link to={'/contact'} className="w-full cursor-pointer bg-[#85C9EA] flex items-center gap-[10px] rounded-[5px] p-[10px]">
                        <ContactIcon />
                        <p className="text-[14px] font-[600]">Contact</p>
                    </Link>
                    {
                        cognitoUser && <>

                            <div className="w-full cursor-pointer bg-[#85C9EA] flex items-center gap-[10px] rounded-[5px] p-[10px]"
                                onClick={() => cognitoUser.signOut(() => {

                                    navigate('/sign_in')

                                })}
                            >
                                <SettingIcon />
                                <p className="text-[14px] font-[600]">Sign Out</p>
                            </div>
                        </>
                    }
                </div>


            </div>
            {children}
        </div>
    );
}

export default BaseLayout;
