import { CognitoUserPool } from 'amazon-cognito-identity-js';

const config = {
    UserPoolId: 'us-west-2_fBEdSmdAF',
    ClientId: '6rcv9un4btgacbas0mfvp7nnf9',
};

const userPool = new CognitoUserPool(config);


export default userPool;