import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { LuLoader2 } from "react-icons/lu";
import ConfirmEmail from '../components/auth/confirm-email';
import loginUser from '../helpers/loginUser';

export default function SignInPage() {
  const navigate = useNavigate()
  const [section, setSection] = useState('login')
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(false)
    setLoading(true)
    try {
      // console.log(data)
      loginUser(data, function () {
        navigate('/workflow')
      })
    } catch (err) {
      console.error(err)
      setMessage(err.message)
      setError(true)
      setSection('code')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='min-w-screen min-h-screen grid grid-cols-2 bg-black text-white'>
      {/* auth form */}
      <div className='h-full w-full flex flex-col gap-5 items-center justify-center'>

        {/* header */}
        <div className='w-full flex justify-center items-center mb-8'>
          <div className='w-3/6 flex flex-col gap-7'>
            {/* logo */}
            <Link to={'/'}>
              <img src="/logo.png" alt="" className='object-cover' />
            </Link>
            <div className='flex flex-col gap-3'>
              <h1 className='text-[24px] font-bold'>Login Into Your Account</h1>
            </div>
          </div>
        </div>

        {section === 'login' &&

          <form onSubmit={handleSubmit} className='w-3/6 grid grid-cols-2 gap-10' autoComplete='off'>
            <div className='flex flex-col gap-2 col-span-2'>
              <label className='font-semibold' htmlFor="email">Email ID</label>
              <input
                onChange={handleChange}
                type="email" name='email' id='email' className='px-2 border border-[#EDEDED] bg-transparent rounded-lg h-[46px]' />
            </div>
            <div className='flex flex-col gap-2  col-span-2'>
              <label className='font-semibold' htmlFor="password">Password</label>
              <input
                onChange={handleChange}
                type="password" name='password' id='password' className='px-2 border border-[#EDEDED] bg-transparent rounded-lg h-[46px]' />
            </div>
            <div className="flex items-center">
              <input id="remember" onChange={handleChange} type="checkbox" value={true} name="remember" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
              <label htmlFor="remember" className="ms-2 text-sm font-medium">Remember me</label>
            </div>

            {/* Error message */}
            <p className='my-2'>{error && message}</p>

            <div className='col-span-2 mt-5'>
              <button type='submit' className='w-full p-2 bg-[#85C9EA] text-black font-semibold rounded-lg' disabled={loading}>
                <LuLoader2 className={`${loading ? 'animate-spin block' : 'hidden'}`} />
                {loading ? 'Loading...' : ''}  {/* loading icon */}
                Login
              </button>
            </div>
          </form>
        }

        {
          section === 'confirm' &&
          <ConfirmEmail email={data.email} />
        }

        {/* Social login */}
        {/* <div className='flex flex-col justify-center items-center gap-5'>
          <p className='text-[#666666] '>Or continue with</p>
          <div className='flex'>
            <button className='border-2 px-5 py-3 flex items-center gap-3 rounded-full hover:border-gray-50/80 hover:text-gray-50/80  '>
              <FcGoogle className='w-7 h-7' /> Continue with Google
            </button>
          </div>
        </div> */}

        {/* alternative link */}
        <div>
          <p className='text-[#666666]'>Don't have an account? <Link to='/sign_up' className='hover:underline hover:text-white'>Register here</Link></p>
        </div>
      </div>

      {/* auth image */}
      <div className='h-full w-full flex justify-end'>
        <img src="/images/auth-image.png" alt="" className='object-cover h-full' />
      </div>
    </div>
  )
}

